import { template as template_60a8eb3ac5a94d63ae876f6fc0816540 } from "@ember/template-compiler";
const FKControlMenuContainer = template_60a8eb3ac5a94d63ae876f6fc0816540(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
