import { template as template_36682a7c3eb046bb89d21361e4d78cfc } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_36682a7c3eb046bb89d21361e4d78cfc(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
